import { Route, Switch } from "react-router-dom";

import "./App.css";

//comps
import Loader from "./comps/Loader.js";
import Logo from "./comps/Logo.js";

function App() {
  //BASE URLS
  let urls = {
    texxture: "https://texxturehome.com",
    di: "https://designideas.net",
    wholesale: "https://wholesale.designideas.net",
  };

  const reidrect = (link) => {
    window.location.href = link;
    return null;
  };

  return (
    <div className="App">
      <header className="App-header">
        <Logo />
        <Loader />
        <Switch>
          <Route
            path="/"
            component={() => reidrect("https://wholesale.designideas.net")}
          />
        </Switch>
      </header>
    </div>
  );
}

export default App;
